import React from 'react'

import './Login.css'
import Navbar from './Navbar/Navbar'

import { Link } from 'react-router-dom'

const Login = ({ submitHandler }) => {
  return (
    <>
    <Navbar />
    <div id="register-form-div">
        <div id="form-header">
            <h1>Login</h1>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-container">
                <div className="form-top">
                    <div className="form-col">
                        <div className="form-row">
                            <label htmlFor="username">Username: </label>
                            <input type="text" id="username" name="username" />
                        </div>
                        <div className="form-row">
                            <label htmlFor="password">Password: </label>
                            <input type="password" id="password" name="password" />
                        </div>
                        <div className="form-row" id="forgot-pwd">
                            <a href="#">Forgot Password?</a>
                        </div>
                        <button type="submit" id="register-btn">SUBMIT</button>
                    </div>
                </div>
            </div>
        </form>
    </div> 
    </>
  )
}

export default Login